import { Icon } from "@iconify/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Login from "../modal/login/Login";
import { useLoadScript } from "@react-google-maps/api";
import { setGuestSelectedUser } from "../../features/guestUserServiceSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { fetchBookingData } from "../../services/booking.service";
import { setBookings } from "../../features/bookingSlice";
import { fetchSavedVehicles } from "../../services/savedVehicles.service";
import { setVehicles } from "../../features/vehicle.slice";
import { fetchSavedLocations } from "../../services/savedLocation.service";
import { setAddresses } from "../../features/location.slice";
import UserDropdown from "../ui/user-dropdown/UserDropdown";
import CartDropdown from "../ui/cart-dropdown/CartDropdown";

const NavbarMain = () => {
  const status = "Online";
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("booking");
  const user = useSelector((state: RootState) => state?.auth?.userData);
  const dispatch = useDispatch();
  const images = ["/images/avatar.png", "/images/avatar.png"];
  const menuItems = [
    { icon: "mdi:user-outline", label: "Profile", link: "#" },
    { icon: "material-symbols:logout", label: "Logout", link: "#" },
  ];
  const [modalShow, setModalShow] = React.useState(false);
  const [menu, setMenu] = useState(true);
  const [currentLocation, setCurrentLocation] = useState("");
  // const currentlocationName = localStorage.getItem(`currLocation`);
  const autocompleteService = useRef<any>();

  const setMenuHandler = () => {
    setMenu((prevMenu) => !prevMenu);
  };

  const closeMenuHandler = () => {
    setMenu((prevMenu) => !prevMenu);
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY as string,
    libraries: ["places"],
  });
  useEffect(() => {
    if (isLoaded) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
  }, [isLoaded]);
  useEffect(() => {
    // Get the current location when the component mounts
    if (isLoaded) {
      getCurrentLocation();
    }
  }, [isLoaded]);

  useEffect(() => {
    if (location.pathname === "/booking-history") {
      setActiveTab("history");
    } else if (location.pathname === "/services") {
      setActiveTab("services");
    } else if (location.pathname === "/") {
      setActiveTab("booking");
    } else if (location.pathname.toLowerCase() === "/profile") {
      setActiveTab("profile");
    } else if (location.pathname === "/about-us") {
      setActiveTab("about");
    } else if (location.pathname === "/for-business") {
      setActiveTab("business");
    } else if (location.pathname === "/privacy-policy") {
      setActiveTab("privacy");
    } else {
      setActiveTab("");
    }
  }, [location.pathname]);

  const getCurrentLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // setCurrentLocation({ lat: latitude, lng: longitude });
          // dispatch(setLatLng({ latitude, longitude }));
          getLocationName(latitude, longitude);
          // dispatch(setCurrLocationName(getLocationName(latitude,longitude)));
          //
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    if (user?.uuid) {
      getBookings();
      fetchSavedCars();
      fetchSavedAddresses();
    }
  }, [user?.uuid]);

  const getBookings = async () => {
    if (!user) return;
    const res = await fetchBookingData();
    dispatch(setBookings(res));
  };

  const fetchSavedCars = async () => {
    try {
      const res = await fetchSavedVehicles();
      console.log(res);
      if (res) dispatch(setVehicles(res.data?.reverse()!));
    } catch (error) {}
  };

  const fetchSavedAddresses = async () => {
    try {
      const res = await fetchSavedLocations();

      if (res) {
        dispatch(setAddresses(res.data.result));
      }
    } catch (error) {}
  };

  function getLocationName(latitude: number, longitude: number) {
    const geocoder = new google.maps.Geocoder();
    const latLng = new google.maps.LatLng(latitude, longitude);

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (
        status === google.maps.GeocoderStatus.OK &&
        results &&
        results.length > 0
      ) {
        const place = results[0];
        // const placeName = place.formatted_address;
        // const filteredCategories = categories.filter(category => category.status === true);
        const areaName = place.address_components.filter(
          (area) => area.types[2] === "sublocality_level_1"
        );
        const cityName = place.address_components.filter(
          (city) => city.types[0] === "administrative_area_level_2"
        );
        const countryName = place.address_components.filter(
          (country) => country.types[0] === "country"
        );
        const placeName = `${areaName[0]?.long_name}, ${cityName[0]?.long_name}, ${countryName[0]?.long_name}`;
        setCurrentLocation(placeName);
        localStorage.setItem("currLocation", placeName!);

        // dispatch(setLocationName(place.formatted_address));
        // dispatch(setCurrLocationName(place.formatted_address));
        return placeName;
      } else {
        console.error("Geocoder failed due to: ", status);
      }
    });
  }

  return (
    <>
      <div>
        <Navbar expand="xl" className="naveBarMain">
          <Container>
            <Row className="w-100 align-items-center p-0 m-0 g-0">
              <Col xs={6} lg={2} className="text-start ">
                <Navbar.Brand href="/">
                  <img src="/images/logo.png" alt="" />
                </Navbar.Brand>
              </Col>
              <Col xs={6} lg={10}>
                <div className="navMain">
                  <ul className="d-xl-flex flex-column flex-xl-row align-items-start align-lg-items-center g-3 d-none">
                    <li>
                      <Link
                        className={`withOutLgn ${
                          activeTab === "booking" ? "active" : ""
                        }`}
                        to="/"
                        onClick={
                          () => {
                            closeMenuHandler();
                            dispatch(
                              setGuestSelectedUser({
                                category: null,
                                carType: null,
                                service: null,
                              })
                            );
                          }
                          // handleNavigation("/", "services")
                        }
                      >
                        <span>Booking</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`withOutLgn ${
                          activeTab === "services" ? "active" : ""
                        }`}
                        to="/services"
                      >
                        <span>Services</span>
                      </Link>
                    </li>
                    {user?.uuid && (
                      <li>
                        <Link
                          className={`withOutLgn ${
                            activeTab === "history" ? "active" : ""
                          }`}
                          to="/booking-history"
                        >
                          <span>Booking History</span>
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link
                        className={`withOutLgn ${
                          activeTab === "about" ? "active" : ""
                        }`}
                        to="/about-us"
                      >
                        <span>About Us</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`withOutLgn ${
                          activeTab === "business" ? "active" : ""
                        }`}
                        to="/for-business"
                      >
                        <span>For Business</span>
                      </Link>
                    </li>
                    {!user?.uuid ? (
                      <li>
                        <Link
                          className={`withOutLgn ${
                            activeTab === "privacy" ? "active" : ""
                          }`}
                          to="/privacy-policy"
                        >
                          <span>Privacy Policy</span>
                        </Link>
                      </li>
                    ) : (
                      <li>
                        <Link
                          className={`withOutLgn ${
                            activeTab === "profile" ? "active" : ""
                          }`}
                          to="/profile"
                        >
                          <span>Profile</span>
                        </Link>
                      </li>
                    )}
                  </ul>
                  {user?.uuid ? (
                    <div className="xl:d-block d-flex align-items-center ">
                      <ul className="rightLinks ms-auto mb-0">
                        <li>
                          <CartDropdown />
                        </li>
                        <li>
                          <UserDropdown
                            status={status}
                            images={images}
                            menuItems={menuItems}
                          />
                        </li>
                      </ul>

                      <Button
                        className="toggle-button d-xl-none inline"
                        onClick={setMenuHandler}
                      >
                        <img src="images/menu-bars.svg" alt="" />
                      </Button>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-end align-items-center p-0">
                      <Link
                        to="#"
                        className="btn btnPrimary ms-auto"
                        onClick={() => setModalShow(true)}
                      >
                        <i>
                          <Icon icon="iconoir:user-circle" />
                        </i>{" "}
                        Sign in
                      </Link>

                      <Button
                        className="toggle-button d-xl-none inline ms-3"
                        onClick={setMenuHandler}
                      >
                        <img src="images/menu-bars.svg" alt="" />
                      </Button>
                    </div>
                  )}

                  {user?.uuid && (
                    <div className="d-xl-none d-block">
                      <UserDropdown
                        status="Online"
                        images={images}
                        menuItems={menuItems}
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </div>
      <Navbar expand="xl" className="bg-white p-0 d-block d-xl-none h-auto">
        <div className="navMenu">
          <Navbar.Collapse
            id="basic-navbar-nav"
            className={`navbarMenu ${menu ? "" : "show"} `}
          >
            <div className="overlay" onClick={closeMenuHandler}></div>
            <Nav className="me-auto">
              <div className="d-xl-none d-block">
                <div className="menuHeader  d-flex">
                  <Link className="brand" to="/">
                    <img src="/images/logo.png" alt="" />
                  </Link>
                  <button className="closeMenu" onClick={closeMenuHandler}>
                    <img src="/images/closeIcon.svg" alt="" />
                  </button>
                </div>
              </div>
              <div className="menu-links">
                <ul className="d-flex flex-column align-items-start g-3">
                  <li>
                    <Link
                      className={`withOutLgn ${
                        activeTab === "booking" ? "active" : ""
                      }`}
                      to="/"
                      onClick={
                        () => {
                          closeMenuHandler();
                          dispatch(
                            setGuestSelectedUser({
                              category: null,
                              carType: null,
                              service: null,
                            })
                          );
                        }
                        // handleNavigation("/", "services")
                      }
                    >
                      <span>Booking</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`withOutLgn ${
                        activeTab === "services" ? "active" : ""
                      }`}
                      to="/services"
                    >
                      <span>Services</span>
                    </Link>
                  </li>
                  {user?.uuid && (
                    <li>
                      <Link
                        className={`withOutLgn ${
                          activeTab === "history" ? "active" : ""
                        }`}
                        to="/booking-history"
                      >
                        <span>Booking History</span>
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link
                      className={`withOutLgn ${
                        activeTab === "about" ? "active" : ""
                      }`}
                      to="/about-us"
                    >
                      <span>About Us</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`withOutLgn ${
                        activeTab === "business" ? "active" : ""
                      }`}
                      to="/for-business"
                    >
                      <span>For Business</span>
                    </Link>
                  </li>
                  {!user?.uuid ? (
                    <li>
                      <Link
                        className={`withOutLgn ${
                          activeTab === "privacy" ? "active" : ""
                        }`}
                        to="/privacy-policy"
                      >
                        <span>Privacy Policy</span>
                      </Link>
                    </li>
                  ) : (
                    <li>
                      <Link
                        className={`withOutLgn ${
                          activeTab === "profile" ? "active" : ""
                        }`}
                        to="/profile"
                      >
                        <span>Profile</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
              <div className="d-xl-none d-block">
                <div className="topbar topbar-pre">
                  <div className="mobileContact d-xl-none block">
                    <ul>
                      <li>
                        <a href="tel:+971501744292">
                          <i>
                            <Icon icon="iconamoon:phone-light" />
                          </i>{" "}
                          +971 501 744 292
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="mailto:support@jino.ae">
                          <i>
                            <Icon icon="ph:envelope-simple" />
                          </i>{" "}
                          support@jino.ae
                        </a>
                      </li>
                      <li>
                        <div className="address ">
                          <i>
                            <Icon icon="material-symbols:location-on-outline" />
                          </i>{" "}
                          <span>{currentLocation}</span>
                        </div>
                      </li>
                    </ul>
                    <div className="appLinks">
                      <p>Download our mobile app</p>
                      <div className="app">
                        <Link to="https://play.google.com/store/apps/details?id=com.os.jinocustomer">
                          <img src="/images/play.png" alt="" />
                        </Link>
                        <Link to="https://apps.apple.com/us/app/jino-on-demand-car-services/id1462862177">
                          <img src="/images/app-store.png" alt="" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <Login show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default NavbarMain;
