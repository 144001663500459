import WhoWeAre from "./components/WhoWeAre";
import ContactUs from "./components/ContactUs";
import ContainerSecondary from "../../Components/layouts/main/ContainerSecondary";
import HeroMain from "./components/HeroMain";
import CounterMain from "./components/CounterMain";
import OurEstablishment from "./components/OurEstablishment";
import WhyChooseUs from "./components/WhyChooseUs";
import { useRef } from "react";
// import Footer from "../../components/footer/Footer";

const AboutUs = () => {
  const targetRef = useRef<HTMLDivElement>(null);

  // Function to handle scrolling to the target element
  const handleScrollToElement = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <ContainerSecondary>
        <HeroMain />
        <WhoWeAre handleScrollToElement={handleScrollToElement} />
        <CounterMain />
        <OurEstablishment />
        <WhyChooseUs />
        <ContactUs ref={targetRef} />
      </ContainerSecondary>
    </>
  );
};

export default AboutUs;
