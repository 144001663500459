import React, {
  forwardRef,
  LegacyRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { post } from "../../../services/api.service";

type FormData = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  reason_message: string;
  message: string;
};

interface ComponentBProps {}

const sitessKey = "6LcyXrMgAAAAACeB2wvDptRe_9t0D_vNFg2QRZbR";

const ContactUs = forwardRef<HTMLDivElement, ComponentBProps>((props, ref) => {
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

  const reCaptchaRef: LegacyRef<ReCAPTCHA> = useRef(null);

  const schema = yup
    .object({
      first_name: yup
        .string()
        .matches(/^[a-z A-Z]*$/, "Only alphabets are allowed")
        .required("First Name is Required."),
      last_name: yup
        .string()
        .matches(/^[a-z A-Z]*$/, "Only alphabets are allowed")
        .required("Last Name is Required."),
      email: yup
        .string()
        .email("This email is not valid")
        .required("Email is Required."),
      phone: yup
        .string()
        .matches(
          /(?:[+\d].*\d|\d)$/,
          "Please enter number in valid format: +971XXXXXXXXXXX"
        )
        .max(15)
        .required("Phone Number is Required."),
      reason_message: yup.string().required("Interest is Required."),
      message: yup.string().required("Message is Required."),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  console.log(errors);

  const onSubmit = async (data: any) => {
    const token = await reCaptchaRef?.current?.executeAsync();
    console.log("first");
    // console.log("Token", token)
    post("contact", {
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      phone: data?.phone,
      message: data?.message,
      reason_message: data?.reason_message,
      reCaptchKey: token,
    })
      .then((response) => {
        setIsSubmitSuccessful(true);
        reCaptchaRef?.current?.reset();

        // console.log('resp', response)
        setTimeout(() => {
          setIsSubmitSuccessful(false);
        }, 2500);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    reset({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      reason_message: "",
      message: "",
    });
  }, [isSubmitSuccessful]);

  return (
    <>
      <section ref={ref} id="contact" className="contactUs">
        <Container>
          <h2>Contact Us</h2>
          <Row>
            <Col lg={7}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col xs={12} lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>First Name*</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("first_name")}
                        placeholder="Your first name"
                      />
                      <p className="error-field">
                        {errors.first_name?.message}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Last Name*</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("last_name")}
                        placeholder="your last name"
                      />
                      <p className="error-field">{errors.last_name?.message}</p>
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email*</Form.Label>
                      <Form.Control
                        type="email"
                        {...register("email")}
                        placeholder="enter your email"
                      />
                      <p className="error-field">{errors.email?.message}</p>
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Phone*</Form.Label>
                      <Form.Control
                        {...register("phone")}
                        type="text"
                        placeholder="Phone Number"
                      />
                      <p className="error-field">{errors.phone?.message}</p>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Subject*</Form.Label>
                      <Form.Control
                        {...register("reason_message")}
                        type="text"
                        placeholder="Add Subject"
                      />
                      <p className="error-field">
                        {errors.reason_message?.message}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Message*</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        {...register("message")}
                        placeholder="Leave a comment here"
                      />
                      <p className="error-field">{errors.message?.message}</p>
                    </Form.Group>
                  </Col>
                </Row>
                <ReCAPTCHA
                  sitekey={sitessKey}
                  size="invisible"
                  ref={reCaptchaRef}
                />
                <div>
                  <Button type="submit" className="cta">
                    Submit
                  </Button>
                </div>
              </form>
            </Col>
            <Col lg={5}>
              <div className="contactWrapper">
                <div className="contactContent">
                  <h4>Contact Details</h4>
                  <p>
                    <Link to="mailto:info@jino.ae">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="19"
                        viewBox="0 0 22 19"
                        fill="none"
                      >
                        <path
                          d="M1.06836 3.5L7.98138 7.41697C10.53 8.86101 11.6068 8.86101 14.1553 7.41697L21.0684 3.5"
                          stroke="#4CC0D0"
                          strokeWidth="1.5"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1.08413 10.9756C1.1495 14.0412 1.18219 15.5739 2.31332 16.7094C3.44444 17.8448 5.01869 17.8843 8.16719 17.9634C10.1077 18.0122 12.0291 18.0122 13.9695 17.9634C17.118 17.8843 18.6923 17.8448 19.8234 16.7094C20.9545 15.5739 20.9872 14.0412 21.0526 10.9756C21.0736 9.98994 21.0736 9.01008 21.0526 8.02439C20.9872 4.95886 20.9545 3.42609 19.8234 2.29066C18.6923 1.15523 17.118 1.11568 13.9695 1.03657C12.0291 0.987813 10.1077 0.98781 8.16718 1.03656C5.01869 1.11566 3.44444 1.15521 2.31331 2.29065C1.18218 3.42608 1.1495 4.95885 1.08412 8.02438C1.0631 9.01007 1.0631 9.98994 1.08413 10.9756Z"
                          stroke="#4CC0D0"
                          strokeWidth="1.5"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span> info@jino.ae</span>
                    </Link>
                  </p>
                  <p>
                    <Link to="tel:+971501744292">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                      >
                        <path
                          d="M19.1183 5.41006C18.2015 4.48399 17.1095 3.74973 15.906 3.25011C14.7024 2.75049 13.4115 2.49552 12.1083 2.50006C6.64835 2.50006 2.19835 6.95006 2.19835 12.4101C2.19835 14.1601 2.65835 15.8601 3.51835 17.3601L2.11835 22.5001L7.36835 21.1201C8.81835 21.9101 10.4483 22.3301 12.1083 22.3301C17.5683 22.3301 22.0183 17.8801 22.0183 12.4201C22.0183 9.77006 20.9883 7.28006 19.1183 5.41006ZM12.1083 20.6501C10.6283 20.6501 9.17835 20.2501 7.90835 19.5001L7.60835 19.3201L4.48835 20.1401L5.31835 17.1001L5.11835 16.7901C4.29609 15.477 3.85949 13.9593 3.85835 12.4101C3.85835 7.87006 7.55835 4.17006 12.0983 4.17006C14.2983 4.17006 16.3683 5.03006 17.9183 6.59006C18.6858 7.35402 19.294 8.26272 19.7077 9.26348C20.1214 10.2642 20.3324 11.3372 20.3283 12.4201C20.3483 16.9601 16.6483 20.6501 12.1083 20.6501ZM16.6283 14.4901C16.3783 14.3701 15.1583 13.7701 14.9383 13.6801C14.7083 13.6001 14.5483 13.5601 14.3783 13.8001C14.2083 14.0501 13.7383 14.6101 13.5983 14.7701C13.4583 14.9401 13.3083 14.9601 13.0583 14.8301C12.8083 14.7101 12.0083 14.4401 11.0683 13.6001C10.3283 12.9401 9.83835 12.1301 9.68835 11.8801C9.54835 11.6301 9.66835 11.5001 9.79835 11.3701C9.90835 11.2601 10.0483 11.0801 10.1683 10.9401C10.2883 10.8001 10.3383 10.6901 10.4183 10.5301C10.4983 10.3601 10.4583 10.2201 10.3983 10.1001C10.3383 9.98006 9.83835 8.76006 9.63835 8.26006C9.43835 7.78006 9.22835 7.84006 9.07835 7.83006H8.59835C8.42835 7.83006 8.16835 7.89006 7.93835 8.14006C7.71835 8.39006 7.07835 8.99006 7.07835 10.2101C7.07835 11.4301 7.96835 12.6101 8.08835 12.7701C8.20835 12.9401 9.83835 15.4401 12.3183 16.5101C12.9083 16.7701 13.3683 16.9201 13.7283 17.0301C14.3183 17.2201 14.8583 17.1901 15.2883 17.1301C15.7683 17.0601 16.7583 16.5301 16.9583 15.9501C17.1683 15.3701 17.1683 14.8801 17.0983 14.7701C17.0283 14.6601 16.8783 14.6101 16.6283 14.4901Z"
                          fill="#4CC0D0"
                        />
                      </svg>
                      <span>+971501744292</span>
                    </Link>
                  </p>
                </div>
                <hr />
                <div className="contactContent">
                  <h4>Operating Hours</h4>
                  <p>
                    <i>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="23"
                        viewBox="0 0 22 23"
                        fill="none"
                      >
                        <circle
                          cx="11.0684"
                          cy="11.5"
                          r="10"
                          stroke="#4CC0D0"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M11.0684 7.5V11.5L13.0684 13.5"
                          stroke="#4CC0D0"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </i>{" "}
                    <span>SAT TO THU 8 AM TO 5 PM</span>
                  </p>
                  <p>
                    <i>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="23"
                        viewBox="0 0 20 23"
                        fill="none"
                      >
                        <path
                          d="M11.6861 20.867C11.2524 21.273 10.6727 21.5 10.0695 21.5C9.46621 21.5 8.88654 21.273 8.45285 20.867C4.48138 17.126 -0.840878 12.9469 1.75463 6.87966C3.15799 3.59916 6.5267 1.5 10.0695 1.5C13.6123 1.5 16.981 3.59916 18.3843 6.87966C20.9766 12.9393 15.6674 17.1389 11.6861 20.867Z"
                          stroke="#4CC0D0"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M13.5684 10.5C13.5684 12.433 12.0014 14 10.0684 14C8.13536 14 6.56836 12.433 6.56836 10.5C6.56836 8.567 8.13536 7 10.0684 7C12.0014 7 13.5684 8.567 13.5684 10.5Z"
                          stroke="#4CC0D0"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </i>{" "}
                    <span>
                      DUBAI INVESTMENT PARK, DUBAI, UNITED ARAB EMIRATES
                    </span>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
});

export default ContactUs;
