import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaXTwitter, FaYoutube } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <Container>
          <Row>
            <Col lg={4}>
              <div className="footer-content">
                <img src="/images/logo.png" alt="" />
                <p>
                  UAE's Leading Car Wash & Oil change solution at your doorstep
                </p>
                <ul className="socialLInks">
                  <li>
                    <Link target="_blank" to="https://x.com/JINOapp">
                      <FaXTwitter />
                    </Link>
                  </li>
                  <li>
                    <Link target="_blank" to="https://www.facebook.com/Jinoapp">
                      <FaFacebookF />
                    </Link>
                  </li>
                  <li>
                    <Link
                      target="_blank"
                      to="https://www.instagram.com/jinoapp/?hl=en"
                    >
                      <FaInstagram />
                    </Link>
                  </li>
                  <li>
                    <Link target="_blank" to="https://www.youtube.com/@jinoapp">
                      <FaYoutube />
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={8}>
              <Row>
                <Col lg={4}>
                  <div className="quick-links">
                    <h4>Company</h4>
                    <ul>
                      <li>
                        <Link to="#">Home</Link>
                      </li>
                      <li>
                        <Link to="#">About</Link>
                      </li>
                      <li>
                        <Link to="#">Services</Link>
                      </li>
                      <li>
                        <Link to="#">Business Inquiry</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="quick-links">
                    <h4>Help</h4>
                    <ul>
                      <li>
                        <Link to="#">Customer Support</Link>
                      </li>
                      <li>
                        <Link to="#">Terms & Conditions</Link>
                      </li>
                      <li>
                        <Link to="#">Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="quick-links">
                    <h4>operating hours</h4>
                    <p>Sat to Thu: 8am - 5pm</p>
                    <p>Dubai Investment Park, UAE</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="copyrights">
        <Container>
          <p>All Rights Reserved By JINO - 2024</p>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
