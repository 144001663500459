import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import CustomSelect from "./CustomSelect";
import { CiCircleInfo } from "react-icons/ci";
import AsyncSelect from "react-select/async";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomDropdown from "./CustomDropdown";
import DropdownPopover from "./DropdownPopover";

import HrDivider from "./HrDivider";
import {
  ChangeEvent,
  FormEvent,
  FormEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { get } from "../../../services/api.service";
import { useDispatch, useSelector } from "react-redux";
import {
  brandsSelector,
  modelsSelector,
  setBrands,
  setModels,
} from "../../../features/brand.slice";
import { Brand, Model } from "../../../Types/vehicle.interface";
import {
  CategoryInterface,
  categoriesSelector,
  resetSelectedCategory,
  selectedCategorySelector,
  setCategories,
  setSelectedCategory,
} from "../../../features/categories.slice";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import {
  CustomerAddress,
  addressesSelector,
  levelIDSelector,
  resetLocationLevelID,
  resetSelectedAddress,
  selectedAddressSelector,
  setAddresses,
  setLocationLevelID,
  setSelectedAddress,
  updateAddressField,
} from "../../../features/location.slice";
import {
  createBooking,
  fetchDayId,
  fetchLevelId,
  fetchSlots,
} from "../../../services/booking.service";
import { GroupBase, OptionsOrGroups } from "react-select";
import {
  CustomerVehiclesAttributes,
  resetSelectedVehicle,
  selectedVehicleSelector,
  setSelectedVehicle,
  setVehicles,
  updateVehicleField,
  vehiclesSelector,
} from "../../../features/vehicle.slice";
import {
  ServiceConfig,
  resetSelectedConfig,
  selectedConfigSelector,
  setServiceConfigs,
} from "../../../features/service.slice";
import {
  fetchServicesConfigView,
  fetchServicesDescription,
} from "../../../services/services.service";
import {
  addVehicle,
  fetchSavedVehicles,
  updateVehicle,
} from "../../../services/savedVehicles.service";
import {
  createLocation,
  editSavedLocation,
  fetchSavedLocations,
} from "../../../services/savedLocation.service";
import toast from "react-hot-toast";
import {
  bookingDetailsSelector,
  bookingSelector,
  daysSelector,
  resetBooking,
  setBookingDetailsFields,
  setBookingFields,
  setDays,
  setSlots,
  slotsSelector,
} from "../../../features/bookingSlice";
import moment from "moment";
import { userSelector } from "../../../features/user.slice";
import { Link, useNavigate } from "react-router-dom";
import Login from "../../../Components/modal/login/Login";
import { RootState } from "../../../store";
import { navItem } from "aws-amplify";
import TagManager from "react-gtm-module";
interface Prediction {
  description: string;
  place_id: string;
}

const vehicle_required = [
  "brand_id",
  "vehicle_model_id",
  "year",
  "area",
  "unitType",
  "label",
  "street_one",
  "start_time",
  "service_config_id",
  "category_id",
];

interface Error {
  brand_id?: boolean;
  vehicle_model_id?: boolean;
  year?: boolean;
  area?: boolean;
  unitType?: boolean;
  label?: boolean;
  street_one?: boolean;
  start_time?: boolean;
  service_config_id?: boolean;
  category_id?: boolean;
}

const colors = [
  { label: "White", value: "#FFFFFF" },
  { label: "Black", value: "#000000" },
  { label: "Silver", value: "#C0C0C0" },
  { label: "Grey", value: "#808080" },
  { label: "Blue", value: "#0000FF" },
  { label: "Red", value: "#FF0000" },
  { label: "Brown", value: "#A52A2A" },
  { label: "Beige", value: "#F5F5DC" },
  { label: "Green", value: "#008000" },
  { label: "Yellow", value: "#FFFF00" },
  { label: "Metallic Blue", value: "#1E90FF" },
  { label: "Metallic Grey", value: "#A9A9A9" },
  { label: "Metallic Black", value: "#0A0A0A" },
  { label: "Pearlescent White", value: "#FDF5E6" },
  { label: "Pearlescent Red", value: "#8B0000" },
  { label: "Metallic Silver", value: "#B0C4DE" },
  { label: "Matte Black", value: "#333333" },
  { label: "Matte Grey", value: "#5A5A5A" },
  { label: "Matte Blue", value: "#3B3B8E" },
  { label: "Gold", value: "#FFD700" },
  { label: "Bronze", value: "#CD7F32" },
  { label: "Champagne", value: "#F7E7CE" },
  { label: "Emerald Green", value: "#50C878" },
  { label: "Ruby Red", value: "#9B111E" },
  { label: "Sapphire Blue", value: "#0F52BA" },
  { label: "Copper", value: "#B87333" },
  { label: "Candy Apple Red", value: "#FF0800" },
  { label: "Midnight Purple", value: "#2E0854" },
  { label: "Electric Blue", value: "#7DF9FF" },
  { label: "Lime Green", value: "#32CD32" },
  { label: "Sunset Orange", value: "#FD5E53" },
  { label: "Turquoise", value: "#40E0D0" },
  { label: "Chameleon", value: "#D4AF37" },
  { label: "Neon Yellow", value: "#FFFF33" },
  { label: "Neon Green", value: "#39FF14" },
  { label: "Pastel Pink", value: "#FFD1DC" },
  { label: "Pastel Blue", value: "#AEC6CF" },
];

const labels = [
  { label: "Home", value: "Home" },
  { label: "Work", value: "Work" },
  { label: "Other", value: "Other" },
];

const units = [
  { label: "Outdoor", value: "Outdoor" },
  { label: "Villa", value: "Villa" },
  { label: "Indoor", value: "Indoor" },
];

const areas = [
  { label: "Palm Jebel Ali", value: "Palm Jebel Ali" },
  { label: "Downtown", value: "Downtown" },
  { label: "Business Bay", value: "Business Bay" },
  { label: "Dubai Marina", value: "Dubai Marina" },
  { label: "Palm Jumeirah", value: "Palm Jumeirah" },
  { label: "Emaar Beachfront", value: "Emaar Beachfront" },
  { label: "MBR City - Meydan", value: "MBR City - Meydan" },
  { label: "Dubai Creek Harbour", value: "Dubai Creek Harbour" },
  { label: "Dubai Hills Estate", value: "Dubai Hills Estate" },
  { label: "Damac Hills", value: "Damac Hills" },
  { label: "Damac Hills II ( Akoya )", value: "Damac Hills II ( Akoya )" },
  { label: "Al Barari", value: "Al Barari" },
  { label: "Al Barsha", value: "Al Barsha" },
  { label: "Al Furjan", value: "Al Furjan" },
  { label: "Al Ghadeer", value: "Al Ghadeer" },
  { label: "Al Habtoor City", value: "Al Habtoor City" },
  { label: "Al Jaddaf", value: "Al Jaddaf" },
  { label: "Al Marjan Island", value: "Al Marjan Island" },
  { label: "Al Safa", value: "Al Safa" },
  { label: "Al Sufouh", value: "Al Sufouh" },
  { label: "Alreeman", value: "Alreeman" },
  { label: "Arabian Ranches", value: "Arabian Ranches" },
  { label: "Arjan - Dubailand", value: "Arjan - Dubailand" },
  { label: "Barsha Heights", value: "Barsha Heights" },
  { label: "Bluewaters Island", value: "Bluewaters Island" },
  { label: "City Walk", value: "City Walk" },
  {
    label: "DHCC - Dubai Healthcare City",
    value: "DHCC - Dubai Healthcare City",
  },
  { label: "DMC - Dubai Maritime City", value: "DMC - Dubai Maritime City" },
  { label: "DSO - Dubai Silicon Oasis", value: "DSO - Dubai Silicon Oasis" },
  { label: "Damac Lagoons", value: "Damac Lagoons" },
  { label: "Dubai Harbour", value: "Dubai Harbour" },
  { label: "Dubai International City", value: "Dubai International City" },
  {
    label: "Dubai International Financial Centre | DIFC",
    value: "Dubai International Financial Centre | DIFC",
  },
  {
    label: "Dubai Investments Park | DIP",
    value: "Dubai Investments Park | DIP",
  },
  { label: "Dubai Islands", value: "Dubai Islands" },
  {
    label: "Dubai Production City | IMPZ",
    value: "Dubai Production City | IMPZ",
  },
  { label: "Dubai South", value: "Dubai South" },
  { label: "Dubai Sports City", value: "Dubai Sports City" },
  { label: "Dubai Studio City", value: "Dubai Studio City" },
  { label: "Dubailand", value: "Dubailand" },
  { label: "Emaar South", value: "Emaar South" },
  { label: "Emirate of Abu Dhabi", value: "Emirate of Abu Dhabi" },
  { label: "Emirate of Ajman", value: "Emirate of Ajman" },
  { label: "Emirate of Ras Al Khaimah", value: "Emirate of Ras Al Khaimah" },
  { label: "Emirate of Sharjah", value: "Emirate of Sharjah" },
  {
    label: "JBR - Jumeirah Beach Residence",
    value: "JBR - Jumeirah Beach Residence",
  },
  { label: "JLT - Jumeirah Lake Towers", value: "JLT - Jumeirah Lake Towers" },
  {
    label: "JVC - Jumeirah Village Circle",
    value: "JVC - Jumeirah Village Circle",
  },
  {
    label: "JVT - Jumeirah Village Triangle",
    value: "JVT - Jumeirah Village Triangle",
  },
  { label: "Jebel Ali Village", value: "Jebel Ali Village" },
  { label: "Jumeirah", value: "Jumeirah" },
  { label: "Jumeirah Bay", value: "Jumeirah Bay" },
  { label: "Jumeirah Golf Estates", value: "Jumeirah Golf Estates" },
  { label: "Jumeirah Islands", value: "Jumeirah Islands" },
  { label: "Jumeirah Pearl", value: "Jumeirah Pearl" },
  { label: "Liwan", value: "Liwan" },
  { label: "Maryam Island", value: "Maryam Island" },
  {
    label: "MJL - Madinat Jumeirah Living",
    value: "MJL - Madinat Jumeirah Living",
  },
  { label: "Meadows", value: "Meadows" },
  { label: "Mina Rashid", value: "Mina Rashid" },
  { label: "Motor City", value: "Motor City" },
  { label: "Mudon", value: "Mudon" },
  { label: "Nad Al Sheba", value: "Nad Al Sheba" },
  { label: "Port De La Mer", value: "Port De La Mer" },
  { label: "Saadiyat Grove", value: "Saadiyat Grove" },
  { label: "Saadiyat Island", value: "Saadiyat Island" },
  { label: "Sobha Hartland", value: "Sobha Hartland" },
  { label: "Sobha Hartland 2", value: "Sobha Hartland 2" },
  { label: "The Heart Of Europe", value: "The Heart Of Europe" },
  { label: "The Valley", value: "The Valley" },
  { label: "Tilal Al Ghaf", value: "Tilal Al Ghaf" },
  { label: "Town Square", value: "Town Square" },
  { label: "Uptown", value: "Uptown" },
  { label: "Warsan First", value: "Warsan First" },
  { label: "World Islands", value: "World Islands" },
  { label: "Yas Island", value: "Yas Island" },
  { label: "Za'abeel", value: "Za'abeel" },
  { label: "Zayed City", value: "Zayed City" },
];

const SearchCard = () => {
  const dispatch = useDispatch();
  const dateRef = useRef<any>(null);
  const brands = useSelector(brandsSelector);
  const models = useSelector(modelsSelector);
  const categories = useSelector(categoriesSelector);
  const address = useSelector(selectedAddressSelector);
  const vehicle = useSelector(selectedVehicleSelector);
  const level = useSelector(levelIDSelector);
  const savedLocations = useSelector(addressesSelector);
  const days = useSelector(daysSelector);
  const slots = useSelector(slotsSelector);
  const config = useSelector(selectedConfigSelector);
  const user = useSelector(userSelector);
  const isLoggedIn = useSelector((state: RootState) => state.auth.userData);
  const savedVehicles = useSelector(vehiclesSelector);
  const bookingDetail = useSelector(bookingDetailsSelector);
  const booking = useSelector(bookingSelector);
  const category = useSelector(selectedCategorySelector);
  const [years, setYears] = useState<{ label: string; value: string }[]>([]);
  const autocompleteService = useRef<any>();
  const [useCurrent, setUseCurrent] = useState(false);
  const [errors, setErrors] = useState<Error>({});
  const [showModal, setShowModal] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const navigate = useNavigate();

  const mapOptions = {
    center: { lat: -34.397, lng: 150.644 }, // Example center point
    zoom: 8, // Example zoom level
    clickableIcons: false, // Optional: Disable clickable icons
    gestureHandling: "none", // Optional: Disable user gestures
  };

  const map = new google.maps.Map(document.createElement("div"), mapOptions);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY as string,
    libraries: ["places"],
  });
  useEffect(() => {
    if (isLoaded) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
  }, [isLoaded]);

  useEffect(() => {
    fetchCarBrands();
    fetchCarModels();
    generateLast100Years();
    fetchCategories();
    getDayIds();
    dispatch(
      setBookingDetailsFields({
        ...bookingDetail,
        bookingAt: !bookingDetail?.bookingAt
          ? moment().format("YYYY-MM-DD")
          : bookingDetail.bookingAt,
      })
    );
  }, []);

  useEffect(() => {
    if (level.uuid && category.uuid) {
      if (config?.service?.service_category_id !== category.uuid) {
        dispatch(resetSelectedConfig());
      }
      let payload: any = {
        level_id: level.uuid,
        service_category_id: category.uuid,
      };
      if (vehicle?.uuid || vehicle.model?.vehicle_type_id) {
        payload["vehicle_type_id"] = vehicle.model?.vehicle_type_id;
        getConfigsWithVehicle(payload);
      } else {
        getConfigsWithoutVehicle(payload);
      }
    }
  }, [level.uuid, category.uuid, vehicle.uuid, vehicle.model]);

  useEffect(() => {
    if (
      bookingDetail?.bookingAt &&
      config?.uuid &&
      address.latitude &&
      address.longitude
    ) {
      getSlots();
    }
  }, [
    bookingDetail?.bookingAt,
    config?.uuid,
    address.latitude,
    address.longitude,
  ]);

  const handleLocationChange = (selectedOption: any) => {
    if (selectedOption.label.includes("Street:")) {
      const address = savedLocations.find(
        (loc) => loc.uuid === selectedOption.value
      );
      if (address) {
        dispatch(
          setSelectedAddress({
            ...address,
            unitType: address.jsonDump.unitType,
          })
        );
        getLocationLevel({ lat: address.latitude, lng: address.longitude });
      }
    } else {
      dispatch(
        updateAddressField({
          area: selectedOption.label,
          uuid: undefined,
          street_one: "",
          label: undefined,
          unitType: undefined,
          zone: undefined,
        })
      );
      handleSearchSelect(selectedOption.value);
    }
  };

  const handleLocationChecked = (e: ChangeEvent<HTMLInputElement>) => {
    setUseCurrent(e.target.checked);
    if (e.target.checked) {
      getCurrentLocation();
    } else {
      dispatch(
        updateAddressField({
          area: undefined,
          uuid: undefined,
          street_one: "",
          label: undefined,
          unitType: undefined,
        })
      );
      dispatch(resetLocationLevelID());
    }
  };

  const fetchCarBrands = async () => {
    try {
      const res = await get("vehicle/brand");
      if (res) {
        const tempArray = res.result;
        tempArray.sort((a: Brand, b: Brand) => {
          return a.name.localeCompare(b.name);
        });

        dispatch(setBrands(tempArray));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchCarModels = async () => {
    try {
      const res = await get("vehicle/model");
      if (res) {
        const tempArray = res.result;

        tempArray.sort((a: Model, b: Model) => {
          return a.name.localeCompare(b.name);
        });
        dispatch(setModels(tempArray));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const generateLast100Years = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const dates = Array.from({ length: 101 }, (_, i) => {
      const year = currentYear - i;
      return {
        label: year.toString(),
        value: year.toString(),
      };
    });
    setYears(dates);
  };

  const fetchCategories = async () => {
    try {
      const res = await get("services/category?status=true");
      if (res?.status)
        dispatch(
          setCategories(
            res?.result.sort((a: any, b: any) => a.priority - b.priority)
          )
        );
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const handleSelectVehicle = (value: Partial<CustomerVehiclesAttributes>) => {
    dispatch(updateVehicleField(value));
  };

  const searchLocations = (search: string): Promise<Prediction[]> => {
    return new Promise((resolve, reject) => {
      const request = {
        input: search,
      };

      if (!autocompleteService.current) {
        reject("Autocomplete service not initialized");
        return;
      }

      autocompleteService.current.getPlacePredictions(
        request,
        (
          predictions: google.maps.places.AutocompletePrediction[],
          status: string
        ) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions.length > 0
          ) {
            resolve(predictions);
          } else {
            resolve([]);
          }
        }
      );
    });
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: any) => void
  ): void => {
    searchLocations(inputValue)
      .then((predictions) => {
        const formattedPredictions = predictions.map((prediction) => ({
          label: prediction.description,
          value: prediction.place_id,
        }));
        const addresses = inputValue
          ? savedLocations
              .filter((loc) =>
                loc.area.toLowerCase().includes(inputValue.toLowerCase())
              )
              .map((loc) => {
                return {
                  label:
                    `${loc.area}, Street No: ${loc.street_one}` || loc.area,
                  value: loc.uuid || "",
                };
              })
          : savedLocations.map((loc) => {
              return {
                label: `${loc.area}, Street No: ${loc.street_one}` || loc.area,
                value: loc.uuid || "",
              };
            });
        callback([
          ...addresses,
          { value: "divider", label: "", isDivider: true },
          ...formattedPredictions,
        ]);
      })
      .catch((error) => {
        console.error("Error loading options:", error);
        callback([]);
      });
  };

  const getCurrentLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getLocationLevel({
            lat: latitude.toString(),
            lng: longitude.toString(),
          });
          getLocationName(latitude, longitude);
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const getLocationLevel = (params: { lat: string; lng: string }) => {
    // // setIsLoading(true);
    fetchLevelId(params.lat, params.lng)
      .then((responseJson) => {
        if (responseJson?.result.length > 0) {
          var locationData = responseJson.result[0];
          // getPromotionsList(locationData.uuid);
          dispatch(setLocationLevelID(locationData));
        } else {
          dispatch(resetLocationLevelID());
          dispatch(setSlots([]));
          // getPromotionsList("91769678-2427-4e72-b240-315fe948a135");
        }
      })
      .catch((err: any) => {
        console.log("timeslot is reset at catch");
        dispatch(resetLocationLevelID());
        dispatch(setSlots([]));
        dispatch(
          setBookingDetailsFields({
            start_time: undefined,
            end_time: undefined,
          })
        );
        console.log("err", err);
      });
  };
  console.log(bookingDetail);
  function getLocationName(latitude: number, longitude: number) {
    const geocoder = new google.maps.Geocoder();
    const latLng = new google.maps.LatLng(latitude, longitude);

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (
        status === google.maps.GeocoderStatus.OK &&
        results &&
        results.length > 0
      ) {
        const place = results[0];
        const placeName = place.formatted_address;
        localStorage.setItem("currLocation", placeName!);
        dispatch(
          updateAddressField({
            area: placeName,
            latitude: latitude.toString(),
            longitude: longitude.toString(),
          })
        );
        return placeName;
      } else {
        console.error("Geocoder failed due to: ", status);
      }
    });
  }

  const handleSearchSelect = (placeId: any) => {
    if (window.google.maps.places && placeId) {
      const service = new window.google.maps.places.PlacesService(map);

      const request = {
        placeId,
        fields: ["geometry"],
      };

      service.getDetails(request, (place: any, status: any) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const { lat, lng } = place.geometry.location;
          const latitude = lat().toString();
          const longitude = lng().toString();
          dispatch(updateAddressField({ latitude, longitude }));
          getLocationLevel({ lat: latitude, lng: longitude });
        }
      });
    }
  };

  const handleSelectCategory = (category: CategoryInterface) => {
    console.log("timeslot is reset at category");
    dispatch(resetSelectedConfig());
    dispatch(
      setBookingDetailsFields({
        start_time: undefined,
        end_time: undefined,
        bookingAt: moment(new Date()).format("YYYY-MM-DD"),
      })
    );
    let payload: any = {
      level_id: level.uuid,
      service_category_id: category.uuid,
    };
    dispatch(setSelectedCategory(category));
    if (vehicle?.uuid || vehicle.model?.vehicle_type_id) {
      payload["vehicle_type_id"] = vehicle.model?.vehicle_type_id;
      getConfigsWithVehicle(payload);
    } else {
      getConfigsWithoutVehicle(payload);
    }
  };

  const getConfigsWithVehicle = (params: any) => {
    fetchServicesDescription(params).then((res) => {
      const configs = res?.data.result.map((cfg: ServiceConfig) => {
        const temp = { ...cfg };
        temp.total = cfg.price;
        return temp;
      });
      dispatch(
        setServiceConfigs(
          configs.sort(
            (a: ServiceConfig, b: ServiceConfig) => a.price - b.price
          )
        )
      );
    });
  };

  const getConfigsWithoutVehicle = (params: any) => {
    fetchServicesConfigView(params)
      .then((res) => {
        const configs = res?.data.result.map((cfg: ServiceConfig) => {
          const temp = { ...cfg };
          temp.total = cfg.price;
          return temp;
        });
        dispatch(
          setServiceConfigs(
            configs.sort(
              (a: ServiceConfig, b: ServiceConfig) => a.price - b.price
            )
          )
        );
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const getDayIds = async () => {
    try {
      const res = await fetchDayId();
      dispatch(setDays(res));
    } catch (error) {
      console.error("Error getting day id:", error);
    }
  };

  const getSlots = async () => {
    const dayId = days.find(
      (day) =>
        day.name === moment(new Date(bookingDetail.bookingAt)).format("dddd")
    );
    dispatch(setBookingDetailsFields({ day_id: dayId?.uuid }));

    const fetchSlotsRequestBody = {
      booking_at: bookingDetail?.bookingAt,
      service_config_id: config?.uuid,
      coordinates: {
        lat: address?.latitude,
        lng: address?.longitude,
      },
      day_id: dayId?.uuid,
    };
    const res = await fetchSlots(fetchSlotsRequestBody);
    //
    try {
      if (res?.error) {
        toast.dismiss();
        dispatch(setSlots([]));
        return;
      }
      if (!res?.error) {
        // dispatch(setScheduleId(res?.data))
        if (res?.data.length < 1) {
          toast.dismiss();
        }
        console.log(res.data);
        const fetchedStartTimes = res.data.slots ? res?.data.slots : res?.data;
        dispatch(setSlots(fetchedStartTimes));
      }
    } catch (error) {
      console.error("Error getting slots:", error);
    }
  };

  console.log(isLoggedIn);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let error: any = {};
    const car: any = {
      ...vehicle,
      ...address,
      service_config_id: config?.uuid,
      start_time: bookingDetail?.start_time,
      category_id: category.uuid,
    };
    vehicle_required.forEach((e) => {
      if (!car[e]) {
        error[e] = true;
      }
    });
    console.log(address);
    setErrors(error);
    if (Object.values(error).length > 0) {
      return;
    }
    if (!isLoggedIn) {
      setShowModal(true);
      return;
    }
    const vehResponse = await handleVehicle();
    const locResponse = !address.uuid ? await handleAddress() : { ...address };
    if (!vehResponse?.uuid && !locResponse?.uuid) {
      return;
    }
    const { uuid, booking_details, ...tempBooking }: any = booking;

    if (uuid) {
      tempBooking.uuid = uuid;
    }
    tempBooking.customer_id = user.uuid;
    tempBooking.source = "Website";
    tempBooking.type = "service";
    tempBooking.details = [
      {
        ...booking_details[0],
        service_config_id: config?.uuid,
        price: config?.price,
        customer_vehicle_id: vehResponse.uuid,
        customer_address_id: locResponse.uuid,
        type: "service",
        status: true,
      },
    ];
    createBooking(tempBooking).then((res) => {
      if (res?.data) {
        dispatch(resetBooking());
        dispatch(resetSelectedConfig());
        dispatch(resetSelectedVehicle());
        dispatch(resetSelectedAddress());
        dispatch(resetSelectedCategory());
        TagManager.dataLayer({
          dataLayer: {
            event: "Booking",
          },
        });
      }
      navigate("/checkout", { state: res?.data.uuid });
    });
  };

  const handleAddress = async () => {
    let locResponse: any;
    const location: CustomerAddress = {
      ...address,
      customer_id: user?.uuid,
      first_name: user?.first_name,
      last_name: user?.last_name,
      mobile_no: user?.phone_number,
      city_id: "3088d8a2-d7e5-48fc-b51f-3748b9d13070",
      status: true,
    };
    locResponse = await createLocation(location);
    dispatch(setSelectedAddress(locResponse.data.result));
    return locResponse.data.result;
  };

  const handleVehicle = async () => {
    let vehResponse: any;
    const car: CustomerVehiclesAttributes = {
      ...vehicle,
      customer_id: user.uuid,
      status: true,
    };
    if (car.uuid) {
      vehResponse = await updateVehicle(car);
    } else {
      vehResponse = await addVehicle(car);
    }
    if (vehResponse.data) {
      dispatch(setSelectedVehicle(vehResponse.data));
      return vehResponse.data;
    } else {
      toast.dismiss();
      toast.error(vehResponse.error);
    }
  };

  const handleMapClick = useCallback((event: google.maps.MapMouseEvent) => {
    const { latLng } = event;
    const latitude = latLng?.lat();
    const longitude = latLng?.lng();
    if (latitude && longitude) {
      dispatch(
        updateAddressField({
          latitude: latitude.toString(),
          longitude: longitude.toString(),
        })
      );
      getLocationName(latitude, longitude);
      getLocationLevel({ lat: latitude.toString(), lng: longitude.toString() });
    }
  }, []);

  const popoverContent = (
    <Popover id="popover-basic" className="tooltipBx">
      <Popover.Body>
        <p>Open map</p>
      </Popover.Body>
    </Popover>
  );
  const popoverLocation = (
    <Popover id="popover-basic" className="tooltipBx">
      <Popover.Body>
        <p>Use current location</p>
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="searchCard">
      <form onSubmit={onSubmit}>
        <div className="formRow">
          <div className="select-icon cst-select form-group carType">
            <Form.Group>
              <Form.Label>Vehicle Type</Form.Label>
              <CustomDropdown
                options={brands}
                error={errors.brand_id ? "Please select car type" : undefined}
                selectedItem={vehicle.brand}
                savedOptions={savedVehicles.map((option) => {
                  const vehColor = colors.find(
                    (color) => color.value === option.color
                  );
                  const name = option.model?.brand?.name + " . " + option.year;
                  return {
                    ...option,
                    name: `${name} ${
                      vehColor?.value ? " . " + vehColor.label : ""
                    }`,
                    image: option.model?.brand?.image,
                  };
                })}
                optionField="name"
                showImage
                placeholder="Select Manufacturer"
                handleSelect={(option) => {
                  if (option.name.includes(" . ")) {
                    const veh = savedVehicles.find(
                      (veh) => veh.uuid === option.uuid
                    );
                    if (veh) {
                      dispatch(
                        setSelectedVehicle({
                          ...veh,
                          brand: veh?.model?.brand,
                          brand_id: veh.model?.brand?.uuid,
                        })
                      );
                    }
                    return;
                  }
                  handleSelectVehicle({
                    brand: option,
                    brand_id: option.uuid,
                    model: undefined,
                    vehicle_model_id: undefined,
                    year: undefined,
                    color: undefined,
                  });
                }}
              />
            </Form.Group>
          </div>
          <div className="select-icon cst-select form-group">
            <Form.Group>
              <Form.Label>Vehicle Make</Form.Label>

              <CustomDropdown
                options={models.filter(
                  (model) => model.brand_id === vehicle.brand_id
                )}
                optionField="name"
                selectedItem={vehicle.model}
                error={
                  errors.vehicle_model_id
                    ? "Please select car model"
                    : undefined
                }
                placeholder="Select make"
                handleSelect={(option) =>
                  handleSelectVehicle({
                    model: option,
                    vehicle_model_id: option.uuid,
                  })
                }
              />
            </Form.Group>
          </div>
          <div className="select-icon cst-select form-group">
            <Form.Group>
              <Form.Label>Year</Form.Label>

              <CustomSelect
                options={years}
                error={errors.year ? "Please select year" : undefined}
                placeholder="Year"
                value={
                  vehicle.year
                    ? { label: vehicle.year, value: vehicle.year }
                    : null
                }
                onChange={(option) =>
                  handleSelectVehicle({ year: option.value })
                }
                withIcon={false}
              />
            </Form.Group>
          </div>
          <div className="select-icon cst-select form-group">
            <Form.Group>
              <Form.Label>Color</Form.Label>
              <CustomSelect
                options={colors}
                value={
                  colors.find((clr) => clr.value === vehicle.color)
                    ? colors.find((clr) => clr.value === vehicle.color)
                    : null
                }
                onChange={(opt) => handleSelectVehicle({ color: opt.value })}
                withIcon={false}
              />
            </Form.Group>
          </div>
        </div>
        <Form.Group className="form-group">
          <div className="location-header">
            <Form.Label className="d-flex justify-content-between">
              Car location{" "}
            </Form.Label>
            <div className="flex-right">
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="top"
                overlay={popoverLocation}
              >
                <div className="switch">
                  <input
                    type="checkbox"
                    id="switch"
                    checked={useCurrent}
                    onChange={handleLocationChecked}
                    className="switch-field"
                    hidden
                  />
                  <label htmlFor="switch"></label>
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="top"
                overlay={popoverContent}
              >
                <Button
                  type="button"
                  className="p-0 ms-2"
                  onClick={() => {
                    setShowMap(!showMap);
                  }}
                >
                  <img src="./images/mage_location-fill.svg" alt="Toggle Map" />
                </Button>
              </OverlayTrigger>
            </div>
          </div>
          <div className="select-icon cst-select locationSearch">
            <AsyncSelect
              classNamePrefix="react-select"
              className={errors.area ? "error" : undefined}
              value={
                address.area
                  ? { label: address.area, value: address.area }
                  : null
              }
              defaultOptions={
                [
                  ...savedLocations.map((loc) => {
                    return {
                      label:
                        `${loc.area}, Street: ${loc.street_one}` || loc.area,
                      value: loc.uuid || "",
                    };
                  }),
                  { value: "divider", label: "", isDivider: true },
                ] as any
              }
              onChange={handleLocationChange}
              loadOptions={loadOptions}
              // options={locationOptions}
              placeholder="Search for a location..."
              isSearchable={true}
              components={{ Option: HrDivider }}
            />
            <img src="./images/location.svg" alt="" />
          </div>
        </Form.Group>
        {showMap && (
          <div className="rounded-4 mb-3">
            <GoogleMap
              mapContainerClassName="map-container"
              mapContainerStyle={{ borderRadius: "8px" }}
              zoom={12}
              center={{
                lat: Number(address.latitude),
                lng: Number(address.longitude),
              }}
              onClick={handleMapClick}
            >
              {isLoaded && address.latitude && address.longitude && (
                <Marker
                  position={{
                    lat: Number(address.latitude),
                    lng: Number(address.longitude),
                  }}
                />
              )}
            </GoogleMap>
          </div>
        )}
        <div className="">
          <Form.Group className="form-group">
            <div className="location-header">
              <Form.Label className="d-flex justify-content-between">
                Street Name{" "}
              </Form.Label>
            </div>
            <div className="select-icon cst-select form-group">
              <Form.Control
                type="text"
                value={address.street_one || ""}
                onChange={(e) =>
                  dispatch(updateAddressField({ street_one: e.target.value }))
                }
                className={`${
                  errors.street_one ? "error" : ""
                } streetNumberInput`}
                placeholder="Enter street name"
              />
            </div>
          </Form.Group>
        </div>
        <Row className="g-2">
          <Col lg="4">
            <div className="select-icon cst-select form-group">
              <Form.Group>
                <Form.Label>Area</Form.Label>
                <CustomSelect
                  options={areas}
                  value={
                    address.zone
                      ? { label: address.zone, value: address.zone }
                      : null
                  }
                  onChange={(option) =>
                    dispatch(
                      updateAddressField({
                        zone: option.value,
                      })
                    )
                  }
                  withIcon={false}
                />
              </Form.Group>
            </div>
          </Col>
          <Col lg="4">
            <div className="select-icon cst-select form-group">
              <Form.Group>
                <Form.Label>Unit Type</Form.Label>
                <CustomSelect
                  error={errors.unitType ? "Please select unit type" : ""}
                  value={
                    address.unitType
                      ? { label: address.unitType, value: address.unitType }
                      : null
                  }
                  onChange={(option) =>
                    dispatch(
                      updateAddressField({
                        unitType: option.value,
                        jsonDump: {
                          ...address.jsonDump,
                          unitType: option.value,
                        },
                      })
                    )
                  }
                  options={units}
                  withIcon={false}
                />
              </Form.Group>
            </div>
          </Col>
          <Col lg="4">
            <div className="select-icon cst-select form-group">
              <Form.Group>
                <Form.Label>Label</Form.Label>
                <CustomSelect
                  error={errors.label ? "Please select label" : ""}
                  value={
                    address.label
                      ? { label: address.label, value: address.label }
                      : null
                  }
                  onChange={(option) =>
                    dispatch(
                      updateAddressField({
                        label: option.value,
                        jsonDump: {
                          ...address.jsonDump,
                          label: option.value,
                        },
                      })
                    )
                  }
                  options={labels}
                  withIcon={false}
                />
              </Form.Group>
            </div>
          </Col>
        </Row>
        <Row className="flex_1">
          <Col lg={6}>
            <Form.Group className="form-group">
              <Form.Label className="d-flex justify-content-between">
                Service{" "}
                <i className="icon">
                  <CiCircleInfo />
                </i>{" "}
              </Form.Label>
              <div
                className={`groupSelection servicesMenu ${
                  errors.service_config_id || errors.category_id ? "error" : ""
                }`}
              >
                <div className="select-icon cst-select">
                  <CustomDropdown
                    options={categories}
                    selectedItem={category}
                    optionField="name"
                    handleSelect={handleSelectCategory}
                    placeholder="Select category"
                  />
                </div>
                <div className="select-icon cst-select">
                  <DropdownPopover />
                </div>
              </div>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="form-group">
              <Form.Label className="d-flex justify-content-between">
                Date & Time{" "}
              </Form.Label>
              <div
                className={`${errors.start_time ? "error" : ""} groupSelection`}
              >
                <div className="relative ">
                  <ReactDatePicker
                    selected={
                      bookingDetail?.bookingAt
                        ? new Date(bookingDetail?.bookingAt)
                        : new Date()
                    }
                    minDate={new Date()}
                    onChange={(date: Date | null) =>
                      dispatch(
                        setBookingDetailsFields({
                          bookingAt: moment(date).format("YYYY-MM-DD"),
                          start_time: undefined,
                          end_time: undefined,
                        })
                      )
                    }
                    dateFormat="MM/dd/yyyy"
                    className="form-control"
                    ref={dateRef}
                  />
                  <img src="./images/calendar-2.svg" alt="" />
                  <div
                    onClick={() => {
                      const datepickerElement = dateRef.current;
                      datepickerElement?.setFocus(
                        !datepickerElement?.state?.open
                      );
                    }}
                    className="iconArrow"
                  ></div>
                </div>
                <div className="select-icon cst-select time-field">
                  <CustomSelect
                    options={slots.map((slot) => {
                      return {
                        label: slot.start_time,
                        value: slot.end_original,
                      };
                    })}
                    noOptionMessage="No Slot Available"
                    placeholder="Timeslot"
                    value={
                      bookingDetail?.start_time
                        ? {
                            label: bookingDetail?.start_time,
                            value: bookingDetail?.end_time,
                          }
                        : null
                    }
                    onChange={(option) =>
                      dispatch(
                        setBookingDetailsFields({
                          start_time: option.label,
                          end_time: option.value,
                        })
                      )
                    }
                    withIcon={false}
                  />

                  <img src="./images/clock.svg" alt="" />
                </div>
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Button type="submit" variant="ctaBtn">
          Book Your Service Now
        </Button>
        <div className="text-center cta-terms">
          <Link to={"/terms-and-conditions"}>Terms and conditions</Link>
        </div>
      </form>
      <Login show={showModal} onHide={() => setShowModal(false)} />
    </div>
  );
};

export default SearchCard;
