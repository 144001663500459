import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

interface IWhoProps {
  handleScrollToElement: () => void;
}

const WhoWeAre = ({ handleScrollToElement }: IWhoProps) => {
  return (
    <>
      <section className="whoWeAre">
        <Container>
          <Row className="align-items-center ">
            <Col lg={6} className="mb-lg-0 mb-5">
              <Row>
                <Col xs={5}>
                  <div className="img-holder mb-4">
                    <img src="./images/h91.png" alt="" className="w-100" />
                  </div>
                  <div className="img-holder">
                    <img src="./images/h92.png" alt="" className="w-100" />
                  </div>
                </Col>

                <Col xs={7}>
                  <div className="img-holder h-100">
                    <img src="./images/h93.png" alt="" className="w-100" />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <div className="content ps-lg-4">
                <h2>WHO WE ARE</h2>
                <p>
                  Jino aims to become the leading provider of car services. With
                  the best and most efficient facilities, processes and people,
                  we will invest in many ways to gain our clients’ trust.{" "}
                </p>
                <p>
                  {" "}
                  Our overall strategy will be based on a continuing improvement
                  process of setting objectives, measuring results and providing
                  feedback to facilitate further growth and progress.
                </p>
                <Link
                  to="#"
                  onClick={handleScrollToElement}
                  className="btn cta-primary"
                >
                  Contact Us
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default WhoWeAre;
